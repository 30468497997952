import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Chart} from 'chart.js';

@Component({
    selector: 'app-averages-chart-modal',
    templateUrl: './averages-chart-modal.page.html',
    styleUrls: ['./averages-chart-modal.page.scss'],
})
export class AveragesChartModalPage implements OnInit {

    //Bar
    @ViewChild('barChart') barChart;

    bars: any;
    colorArray: any;
    report;
    name: string;

    constructor(private modalCtr: ModalController) {
    }


    ngOnInit() {
        const taste = this.name.split('-')[0];
        const labels = this.report.weeks.map(s => `Week ${s.week_no}`);
        const values = this.report.weeks.map(s => parseFloat(s[`weekly_${taste}s`]));

        this.name = (`${this.name.replace('-', ' ')} CHART`).toLocaleUpperCase();

        this.bars = new Chart(this.barChart.nativeElement, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Averaged Numbers',
                    data: values,
                    backgroundColor: 'rgb(194,38,87)', // array should have same number of elements as number of dataset
                    borderWidth: 0
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    async close() {
        await this.modalCtr.dismiss();
    }

}
