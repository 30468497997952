import {Injectable} from '@angular/core';
import {ApiService} from './services/api.service';
import {AlertController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(
        public api: ApiService,
        public alertController: AlertController,
        public navCtrl: NavController,
        public r: Router,
        public storage: Storage
    ) {
    }

    post(auth) {
        return this.api.login('oauth/token', auth);
    }

    logout() {
        return this.presentAlertConfirm();
    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            message: this.api.appName,
            buttons: [

                {
                    text: 'Pest Info',
                    handler: () => {
                        this.r.navigateByUrl('/pest-control');

                    }
                },

                {
                    text: 'Logout',

                    cssClass: 'secondary',
                    handler: (blah) => {
                        localStorage.clear();
                        localStorage.removeItem('token');

                        this.navCtrl.navigateRoot('/login');
                    }

                }, {
                    text: 'Exit',
                    handler: () => {
                        navigator['app'].exitApp();

                    }
                },

                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        //console.log('Confirm Cancel: blah');
                    }
                },
            ]
        });

        await alert.present();
    }

    register(data: any) {
        return this.api.post('v1/users', data);
    }
}
