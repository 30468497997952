import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');
        const headers = {
            'Accept': 'application/vnd.api+json',
            Authorization: `Bearer ${token}`
        };

        if (request.headers.has('Set-Type')) {
            if (request.headers.get('Set-Type') !== 'auto') {
                headers['Content-Type'] = request.headers.get('Set-Type');
            }
        } else {
            headers['Content-Type'] = 'application/vnd.api+json';
        }

        request = request.clone({
            setHeaders: headers
        });

        return next.handle(request);
    }
}
