import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

    constructor(
        private api: ApiService,
        private storage:Storage
    ) {
    }

    add(warehouse: any) {

        //var headers = new Headers().set('Content-Type', 'application/json');//for ios
        // var headers = new Headers().set('Content-Type','application/x-www-form-urlencoded');//for android
        return this.api.post('v1/monitors', warehouse);
    }


    delete(id){
        return this.api.delete('api/v1/monitors/'+ id);


    }

    update(monitor,id){
        return this.api.patch('api/v1/monitors/'+id, monitor);

    }





}

