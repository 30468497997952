import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'reports', loadChildren: './reports/reports.module#ReportsPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'reports-detail', loadChildren: './reports-detail/reports-detail.module#ReportsDetailPageModule' },
  { path: 'monitor-tab', loadChildren: './monitor/monitor-tab.module#MonitorTabPageModule' },
  { path: 'merchant-tab', loadChildren: './merchant/merchant-tab.module#MerchantPageModule' },
  { path: 'warehouse-tab', loadChildren: './warehouses/warehouse/warehouse-tab.module#WarehouseTabPageModule' },
  { path: 'monitor-reports', loadChildren: './monitor-reports/monitor-reports.module#MonitorReportsPageModule' },
  { path: 'monitor-edit', loadChildren: './monitor-edit/monitor-edit.module#MonitorEditPageModule' },
  { path: 'reset', loadChildren: './reset/reset.module#ResetPageModule' },
  { path: 'jobs-tab', loadChildren: './jobs-tab/jobs-tab.module#JobsTabPageModule' },
  { path: 'jobs', loadChildren: './jobs/jobs.module#JobsPageModule' },
  { path: 'view-jobs', loadChildren: './view-jobs/view-jobs.module#ViewJobsPageModule' },
  { path: 'view-chemicals', loadChildren: './view-chemicals/view-chemicals.module#ViewChemicalsPageModule' },
  { path: 'view-products', loadChildren: './view-products/view-products.module#ViewProductsPageModule' },
  { path: 'view-job-types', loadChildren: './view-job-types/view-job-types.module#ViewJobTypesPageModule' },
  { path: 'gas-monitors', loadChildren: './gas-monitors/gas-monitors.module#GasMonitorsPageModule' },
  { path: 'add-job', loadChildren: './add-job/add-job.module#AddJobPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'job-detail', loadChildren: './job-detail/job-detail.module#JobDetailPageModule' },
  { path: 'gas-monitor-detail', loadChildren: './gas-monitor-detail/gas-monitor-detail.module#GasMonitorDetailPageModule' },
  { path: 'stacks-detail', loadChildren: './stacks-detail/stacks-detail.module#StacksDetailPageModule' },
  { path: 'gas-monitor-edit', loadChildren: './gas-monitor-edit/gas-monitor-edit.module#GasMonitorEditPageModule' },
  { path: 'add-stack', loadChildren: './add-stack/add-stack.module#AddStackPageModule' },
  { path: 'edit-job', loadChildren: './edit-job/edit-job.module#EditJobPageModule' },
  { path: 'edit-stack', loadChildren: './edit-stack/edit-stack.module#EditStackPageModule' },
  { path: 'warehouse-detail', loadChildren: './warehouse-detail/warehouse-detail.module#WarehouseDetailPageModule' },
  { path: 'warehouse-edit', loadChildren: './warehouse-edit/warehouse-edit.module#WarehouseEditPageModule' },
  { path: 'pest-control', loadChildren: './pest-control/pest-control.module#PestControlPageModule' },
  { path: 'averages-chart-modal', loadChildren: './averages-chart-modal/averages-chart-modal.module#AveragesChartModalPageModule' }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
