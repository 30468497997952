import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Storage} from '@ionic/storage';
import {MasterDetailService} from './master-detail.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(
        private api: ApiService,
        public storage: Storage,
        public g: MasterDetailService,
    ) {
    }

    query() {
        return this.storage.get('users');
    }

    queryUserLive() {
        return this.api.getLive('v2/user');
    }
}
