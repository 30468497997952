import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

    constructor(
        private api: ApiService,
        public storage:Storage

    ) {
    }

    add(warehouse: any) {
        var headers = new Headers().set('Content-Type', 'application/json');//for ios
        // var headers = new Headers().set('Content-Type','application/x-www-form-urlencoded');//for android
        return this.api.post('merchant/add', warehouse);
    }

    query() {
        return this.storage.get('merchants')
    }

    queryLive() {
        return this.api.get('v1/merchants')
    }

    queryF() {
        return this.api.get('v1/fumigators?include=user')
    }
}
