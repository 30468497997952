import {Component} from '@angular/core';
import {LoadingController, ModalController, ToastController} from '@ionic/angular';
import {WarehouseService} from '../../services/warehouse.service';
import {MerchantService} from '../../services/merchant.service';
import {LoginService} from '../../login.service';
import {UsersService} from '../../services/users.service';

@Component({
    selector: 'warehouse-tab',
    templateUrl: 'warehouse-tab.page.html',
    styleUrls: ['warehouse-tab.page.scss']
})
export class WarehouseTabPage {
    public wid: number;
    public merchantOption: any;
    public name: any;
    public wtId: number;
    public mid: number;

    public warehouse_type: any;
    fumigators: any;
    fid = [];
    data: any;
    public admin: boolean;
    public merchant1: boolean;
    public merchant2: boolean;
    public rol: any;
    public role: any;
    public id: any;


    constructor(public loadingController: LoadingController,
                public toastController: ToastController,
                public warehouse: WarehouseService,
                public log: LoginService,
                public modalCtrl: ModalController,
                public roles: UsersService,
                public merchant: MerchantService,
    ) {
        this.loadDat();
        this.loadOptions();
    }

    async presentToast(id) {
        const toast = await this.toastController.create({
            message: id,
            duration: 3000,
            position: 'top',
        });
        toast.present();
    }

    async submit() {
        if (this.wid && this.wtId && this.name) {


            const postData = {
                'data': {
                    'type': 'warehouses',
                    'attributes': {
                        'wid': this.wid,
                        'name': this.name,
                        'warehouse_type_id': this.wtId,
                        'merchant_id': this.mid,
                        'fumigator_id': this.fid.join(','),
                    }
                }
            };


            const loading = await this.loadingController.create({
                message: 'Please Wait...',
                duration: 2000
            });

            await loading.present();


            this.warehouse.add(postData).subscribe(
                success => {
                    this.presentToast('Your data has been saved.');
                    loading.dismiss();

                    this.wid = null;
                    this.name = null;
                    this.wtId = null;
                    this.mid = null;
                    this.fid = null;
                    this.data = 'u';
                }, e => {
                    this.presentToast('Try Again There Was an Error!');
                    loading.dismiss();
                });
        } else {
            this.presentToast('Please Fill all the fields');
        }

// this.loading.dismiss()
    }

    loadOptions() {
        this.warehouse.queryTypeLive().subscribe((res: any) => {
            this.warehouse_type = res.data;
        });


        this.merchant.queryLive().subscribe((res: any) => {
            this.merchantOption = res.data;
        });


        this.merchant.queryF().subscribe((res: any) => {
            this.fumigators = [];

            for (const d of res.data) {
                try {
                    this.id = d.relationships.user.data.id;

                    const fum = (res.included.find(u => this.id === u.id && u.type === 'users') || {}).attributes;

                    this.fumigators.push({
                        id: d.id,
                        name: fum.name || fum.company || fum.email,
                    });

                } catch (e) {
                }
            }
        });
    }

    closeModalX() {
        this.modalCtrl.dismiss();

    }

    closeModal() {
        this.modalCtrl.dismiss({
            data: this.data
        }).then(e => {

        }).catch(e => {

        });

    }

    loadDat() {
        this.roles.queryUserLive().subscribe((res: any) => {
            this.role = res.roles;
            console.log(res);
            try {

                console.log('ROLELEL', this.role[0].name);
                this.rol = this.role[0].name;
            } catch (e) {

            }
            switch (this.rol) {
                case 'admin':
                    this.admin = true;
                    break;

                case 'merchant1':
                    this.merchant1 = true;
                    break;
                case 'merchant2':
                    this.merchant2 = true;
                    break;


            }


        }, e => {
            console.log(e);

        });
    }

}
