import {Component} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Storage} from '@ionic/storage';
import {SyncService} from './sync.service';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
    private logged: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl:NavController,
    public synch:SyncService,
    public nativeStorage:Storage,
    public auth:AuthService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#A01D26');
      this.statusBar.overlaysWebView(false);
      this.splashScreen.hide();
      if ( this.auth.isAuthenticated()){
        console.log('You are in')
        this.synch.sync()
        setInterval(o=>{
          this.synch.sync()
        },60000)
        this.navCtrl.navigateRoot('/')

      }else {
       this.navCtrl.navigateRoot('/login')
      }
      // this.nativeStorage.get('logged').then(val=>{
      //   this.logged = val;
      //
      //     if(this.logged == 'true'){
      //         this.navCtrl.navigateRoot('/')
      //
      //     }
      //     else {
      //       //
      //
      //     }
      //   }).catch(e=>{
      //    //
      //
      // });

    });


    }

}
