import { Component, OnInit } from '@angular/core';
import {LoadingController, ModalController, NavController, ToastController} from '@ionic/angular';
import {WarehouseService} from '../services/warehouse.service';
import {Storage} from '@ionic/storage';
import {MasterDetailService} from '../services/master-detail.service';
import {MonitorService} from '../services/monitor.service';
import {LoginService} from '../login.service';

@Component({
  selector: 'app-monitor-edit',
  templateUrl: './monitor-edit.page.html',
  styleUrls: ['./monitor-edit.page.scss'],
})
export class MonitorEditPage implements OnInit {

  public wid: number ;
  public trap: number;
  public moth: number;
  public beetle: number;
  public notes: any;
  public warehouseOption:any;
  public date: any;
  clients: any;



  constructor(public loadingController: LoadingController,
              public toastController: ToastController,
              public warehouse:WarehouseService,
              public storage:Storage,
              public ms:MasterDetailService,
              public navCtrl:NavController,

              public monitor:MonitorService,
              public modalCtrl: ModalController,
              public log:LoginService,

  ) {
    this.loadOptions();
    this.moth = ms.getValue().monitors.moth
    this.wid = ms.getValue().monitors.warehouse_id
    this.notes = ms.getValue().monitors.notes
    this.date = this.ms.getValue().monitors.created_at
    this.trap = ms.getValue().monitors.trap
    this.beetle = ms.getValue().monitors.beetle

  }

  async presentToast(id) {
    const toast = await this.toastController.create({
      message: id,
      duration: 3000,
      position:'top',
    });
    toast.present();
  }

  async   submit() {

    if (this.wid&&this.trap) {

      let postData = {
        "data": {

          "type": "monitors",
          "id":this.ms.getValue().id,
          "attributes": {
            "warehouse_id": this.wid,
            "trap": this.trap,
            "beetle": this.beetle,
            "moth": this.moth,
            "notes": this.notes,

          }}


      };

      const loading =  await this.loadingController.create({
        message: 'Please Wait...',
        duration: 60000
      });
      await loading.present();


      console.log(JSON.stringify(postData));
      this.monitor.update(postData,this.ms.getValue().id).subscribe(
          success => {
            this.presentToast('Your data have been saved.');
            loading.dismiss();
            this.closeModal()
            // this.wid = null;
            //this.trap = null;

            this.moth = null;
            this.beetle = null;
            this.notes = ''
          },e => {
        this.presentToast('Try Again There Was an Error!');
        loading.dismiss();
        console.log(e)
      })
    }else {
      this.presentToast('Please Fill all the fields')
    }

    // this.loading.dismiss()
  }

  loadOptions(){

      this.warehouse.queryLive().subscribe((res:any)=>{
        this.warehouseOption = res.data
      })


  }

  closeModal() {

    this.modalCtrl.dismiss({
      t:'u'
    }).then(e=>{

    }).catch(e=>{

    })

  }
  lgt(){
    this.log.logout()



  }

  reset(){

  }

  closeModalX() {
    this.modalCtrl.dismiss()
  }


  toNum(id){
    return Number(id)
  }

  ngOnInit(): void {
  }
}
