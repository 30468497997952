import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {MasterDetailService} from './master-detail.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    //url: string = 'https://fumigation.pnrhost.com';
//  url: string = 'http://app.fumigationsolutions.com';

    url = 'https://dev.ppcs.co.zw';
    appName = 'Fumigation Services';
    headers: any = {};
    token: any;

    constructor(
        private HTTP: HttpClient,
        public storage: Storage,
    ) {
    }

    get<T>(endpoint: string, params?: any, reqOpts?: any, absolute = false) {

        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams()
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            for (const k of Object.keys(params)) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }


        return this.HTTP.get<T>(absolute ? endpoint : `${this.url}/api/${endpoint}`);
    }


    getLive(endpoint) {


        // Support easy query params for GET requests


        return this.HTTP.get(this.url + '/api/' + endpoint);

        // return this.cache.loadFromObservable(cacheKey, request);t
    }

    post<T>(endpoint: string, body: any, headers?: Object, absolute = false) {

        let _headers = new HttpHeaders();

        if (headers) {
            for (const key of Object.keys(headers)) {
                _headers = _headers.append(key, headers[key]);
            }
        }

        return this.HTTP.post<T>(this.url + (absolute ? '' : '/api/') + endpoint, body, {
            headers: _headers,
        });
    }

    login(endpoint: string, body: any, reqOpts?: any, headers?: string) {
        return this.HTTP.post(this.url + '/' + endpoint, body);
    }

    put(endpoint: string, body: any, reqOpts?: any, headers?: string) {

        return this.HTTP.put(this.url + '/' + endpoint, body);
    }

    patch(endpoint: string, body: any, reqOpts?: any, headers?: string) {
        return this.HTTP.patch(this.url + '/' + endpoint, body);
    }


    delete(s: string) {
        return this.HTTP.delete(this.url + '/' + s);
    }
}

