import {Injectable} from '@angular/core';
import {ApiService} from './services/api.service';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class SyncService {

    constructor(public api: ApiService, public storage: Storage) {
    }

    error(err) {
        alert('An error occurred while loading the app, please reload');
        console.error(err);
    }

    sync() {
        this.api.get('v2/user')
            .toPromise()
            .then(res => this.storage.set('user', res))
            .catch(this.error);

        this.api.get('v1/merchants')
            .toPromise()
            .then(res => this.storage.set('merchants', res))
            .catch(this.error);


        this.api.get('warehousetypes')
            .toPromise()
            .then(res => this.storage.set('warehousetypes', res))
            .catch(this.error);


        this.api.get('v1/chemicals')
            .toPromise()
            .then(res => this.storage.set('chemicals', res))
            .catch(this.error);


        this.api.get('warehouses')
            .toPromise()
            .then(res => this.storage.set('warehouses', res))
            .catch(this.error);
    }
}
