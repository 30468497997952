import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, ToastController} from '@ionic/angular';
import {WarehouseService} from '../services/warehouse.service';
import {LoginService} from '../login.service';
import {MerchantService} from '../services/merchant.service';
import {MasterDetailService} from '../services/master-detail.service';
import {UsersService} from '../services/users.service';

@Component({
  selector: 'app-warehouse-edit',

  templateUrl: './warehouse-edit.page.html',
  styleUrls: ['./warehouse-edit.page.scss'],
})
export class WarehouseEditPage implements OnInit, AfterViewInit {

  public wid: any;
  public merchantOption: any
  public name: any;
  public wtId: any;
  public mid: any;
  public warehouse_type: any;
  public fumigator_id: any;
  fumigators: any;
  public admin: boolean;
  public merchant1: boolean;
  public merchant2: boolean;
  public rol: any;
  public role: any;
  public fumigatorss: any;
  public cm: any;
  public id: any;
    public ty: any;

  constructor(public loadingController: LoadingController,
              public toastController: ToastController,
              public warehouse: WarehouseService,
              public log: LoginService,
              public ref:ChangeDetectorRef,
              public s:MasterDetailService,
              public modalCtrl: ModalController,
              public roles:UsersService,
              public merchant: MerchantService,
  ) {

    this.loadDat()
console.log(this.s.getValue())

  this.wid = this.s.getValue().warehouses.wid
  this.name = this.s.getValue().warehouses.name
  this.wtId = this.s.getValue().warehouses.warehouse_type_id
  this.mid = this.s.getValue().warehouses.merchant_id
  this.fumigator_id = this.s.getValue().warehouses.fumigator_id

      this.loadOptions()

  }

  async presentToast(id) {
    const toast = await this.toastController.create({
      message: id,
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async submit() {
    if (this.wid && this.mid && this.wtId && this.name ) {

      let postData = {



        "data": {
          "type": "warehouses",
          "id": this.s.getValue().id,
          "attributes": {
            "wid": this.wid,
            "name": this.name,
            "warehouse_type_id": this.wtId,
            "merchant_id": this.mid,
            "fumigator_id": this.fumigator_id,

          }}}

      const loading = await this.loadingController.create({
        message: 'Please Wait...',
        duration: 2000
      });
      await loading.present();


      this.warehouse.edit(this.s.getValue().id,postData).subscribe(
          success => {
            this.presentToast('Your data have been saved.');
            loading.dismiss();
            this.closeModal()

          }, e => {
            this.presentToast('Try Again There Was an Error!');
            loading.dismiss()
          })
    } else {
      this.presentToast('Please Fill all the fields')
    }

// this.loading.dismiss()
  }

  loadOptions() {
    this.warehouse.queryTypeLive().subscribe((res:any) => {
        let jt = []
        this.ty = res.data

        for (let j of this.ty){


            const  data = {
                name:j.attributes.name,
                id:j.id
            }
            jt.push(data)

        }
        this.warehouse_type = jt
        console.log(this.warehouse_type)

    });


    this.merchant.queryLive().subscribe((res:any) => {
      this.merchantOption = res.data;
    });
    this.merchant.queryF().subscribe((res:any)=>{
      this.fumigators = res.data
      const pos =[]

      this.cm = res
      for (let d of this.cm.data){
        let p;

        try {
          this.id = d.relationships.user.data.id

        }
        catch (e) {
        }
        let fum :any = {}

        for (let u of this.cm.included){
          if (this.id == u.id && u.type == 'users') {
            fum = u.attributes
          }

        }

        p = {
          id:Number(d.id),
          fum:fum.name,
        }

        pos.push(p)
      }
      this.fumigatorss = pos
      console.log(this.fumigatorss)
    })

  }

  closeModalX() {
    this.modalCtrl.dismiss()

  }

  closeModal() {
    this.modalCtrl.dismiss({
      data: 'u'
    }).then(e => {

    }).catch(e => {

    })

  }
  loadDat(){


    this.roles.queryUserLive().subscribe((res:any)=>{
      this.role = res.roles
      console.log(res)
      try {

        console.log("ROLELEL", this.role[0].name);
        this.rol = this.role[0].name;
      }catch (e) {

      }
      switch (this.rol) {
        case "admin":this.admin = true;
          break;

        case "merchant1":this.merchant1 = true
          break;
        case "merchant2":this.merchant2 = true
          break;


      }


    },e=>{
      console.log(e)

    });
  }



  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
   //this.ref.detectChanges()
  }

    toNumber(id: any) {
        return Number(id)
    }
}
