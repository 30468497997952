import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {ApiResponse, Index, MerchantFumigator, Warehouse} from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    constructor(
        public api: ApiService,
        public http: HttpClient,
        public storage: Storage
    ) {
    }

    query(report: any) {
        return this.api.get('reports/' + report);
    }

    queryLink(link) {
        return this.http.get(link);
    }

    queryr(report: any) {
        return this.api.get('reports/' + report + '?page[size]=50');
    }

    filteredQueryMoths(
        date?: string,
        date2?: string,
        mid?: string,
        wid?: string,
        wtid?: string,
        moths?: number,
        moths_max?: number,
        typpe?: any
    ) {
        return this.api.get(
            'v1/reports/' +
            typpe +
            '?page_size=50&filter=' +
            `{"moths_min":${moths},"moths_max":${moths_max},"warehouse_type_id":"${wtid}",
            "warehouse_id":"${wid}","merchant_id":"${mid}","dateRange":{"startDate":"${date}","endDate":"${date2}"}}`
        );
    }

    queryJobs(type: string) {
        return this.api.get('v1/' + type); //+'?page[number]=1')
    }

    addJob(data: any) {
        return this.api.post('v1/jobs', data);
    }

    editJob(id, data: any) {
        return this.api.patch('api/v1/jobs/' + id, data);
    }

    getJob(id) {
        return this.api.get('api/v1/jobs/' + id);
    }

    queryStacks(id: any) {
        return this.api.get('v1/stacks?filter[job_id]=' + id);
    }

    queryGasStack(id: any) {
        return this.api.get('v1/stackmonitors?filter[stack_id]=' + id);
    }

    deleteGasMonitor(id: any) {
        return this.api.delete('api/v1/stackmonitors/' + id);
    }

    queryChemicals() {
        return this.api.get('v1/chemicals');
    }

    addStack(data: any) {
        return this.api.post('stacks/create', data);
    }

    editStack(id: any, data: any) {
        return this.api.patch('api/v1/stacks/' + id, data);
    }

    editGas(id: any, data: any) {
        return this.api.patch('api/v1/stackmonitors/' + id, data);
    }

    addGas(data: any) {
        //return this.api.post('v1/stackmonitors', data);
        return this.api.post('stack-monitor/add', data);
    }

    queryJobsOffline(jobs: any) {
        return this.storage.get(jobs);
    }

    queryJobTypes() {
        return this.api.get('v1/jobtypes');
    }

    queryUser(client_id: string) {
        return this.api.get('v1/users/' + client_id);
    }

    queryMonitor(warehouse: any, trap: any, week: any) {
        return this.api.get(
            'v1/monitors?page[size]=50&include=warehouse.merchant.user&filter[search]=' +
            `{"trap_no":${trap},"week_no":${week},"warehouse_id":"${warehouse}"}`
        );
    }

    queryWarehouses() {
        return this.api.get('v1/warehouses');
    }

    queryWarehousesf() {
        return this.api.get(
            'v1/warehouses?include=merchant,fumigator,warehouse_type'
        );
    }

    getWarehouses() {
        return this.api.get<Warehouse[]>('warehouses');
    }

    getMerchants() {
        return this.api.get<ApiResponse<Index[]>>('v1/merchants');
    }

    getFumigatorMerchants() {
        return this.api.get<ApiResponse<MerchantFumigator[]>>('fumigator/merchants');
    }

    getJobProduct(productId) {
        return this.api.get<ApiResponse<MerchantFumigator[]>>('job/product/' + productId);
    }

    getJobStacks(jobId) {
        return this.api.get<ApiResponse<MerchantFumigator[]>>('job/stacks/' + jobId);
    }
}
