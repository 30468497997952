import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http/';
import {IonicStorageModule} from '@ionic/storage';
import {TokenInterceptor} from './token.interceptor';
import {AuthService} from './auth.service';
import {MonitorEditPageModule} from './monitor-edit/monitor-edit.module';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AddJobPageModule} from './add-job/add-job.module';
import {ComponentsModule} from './components/components.module';
import {EditJobPageModule} from './edit-job/edit-job.module';
import {WarehouseEditPageModule} from './warehouse-edit/warehouse-edit.module';
import {WarehouseTabPageModule} from './warehouses/warehouse/warehouse-tab.module';
import { AveragesChartModalPageModule} from './averages-chart-modal/averages-chart-modal.module';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [BrowserModule, IonicModule.forRoot({
        mode: 'ios'
    }),
        HttpClientModule,
        AppRoutingModule,
        EditJobPageModule,

        MonitorEditPageModule,
        ComponentsModule,
        //EditStackPageModule,
        WarehouseEditPageModule,
        WarehouseTabPageModule,
        AddJobPageModule,
        AveragesChartModalPageModule,
        IonicStorageModule.forRoot(
            {
                name: '__fm',
                driverOrder: ['indexeddb', 'sqlite', 'websql']
            }
        ),
    ],
    providers: [
        StatusBar,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },

        SplashScreen,
        AuthService,
        InAppBrowser,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
