import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService {

    constructor(
        private api: ApiService,
        private storage: Storage
    ) {
    }

    add(warehouse: any) {
        return this.api.post('v1/warehouses', warehouse);
    }

    queryLive(warehouse?: any) {
        return this.api.get('v1/warehouses?sort=wid');
    }

    queryTypeLive(warehouse?: any) {
        return this.api.get('v1/warehousetypes');
    }

    edit(id: any, postData: any) {
        return this.api.patch('api/v1/warehouses/' + id, postData);
    }

    delete(id: any) {
        return this.api.delete('api/v1/warehouses/' + id);
    }
}
