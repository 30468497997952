import {Component, OnInit} from '@angular/core';
import {AlertController, LoadingController, ModalController, ToastController} from '@ionic/angular';
import {ReportsService} from '../services/reports.service';
import * as moment from 'moment';
import {MasterDetailService} from '../services/master-detail.service';
import {MerchantFumigator} from '../../models';

@Component({
    selector: 'app-edit-job',
    templateUrl: './edit-job.page.html',
    styleUrls: ['./edit-job.page.scss'],
})
export class EditJobPage implements OnInit {
    merchantFumigators: MerchantFumigator[];
    status: any;
    comments: any;
    end_date: any;
    location: any;
    start_date: any;
    client: number;
    clients: any;
    ref2: any;
    ref1: any;
    public jobtypes: any;
    public products: any;
    product: any;
    job_type: number;
    public loading: any;
    public data: any;
    public jobty: any;
    public cl: any;
    public clientss: any;
    public id: any;

    constructor(
        public modal: ModalController,
        public report: ReportsService,
        public alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public loadingCtrl: LoadingController,
        public ms: MasterDetailService
    ) {
    }

    ngOnInit() {

        this.report.getFumigatorMerchants()
            .toPromise()
            .then(r => this.merchantFumigators = r.data);

        this.report.queryJobTypes()
            .subscribe((res: any) => {
                const jt = [];
                this.jobty = res.data;

                for (const j of this.jobty) {

                    console.log(j);
                    const data = {
                        name: j.attributes.name,
                        id: j.id
                    };
                    jt.push(data);

                }
                this.jobtypes = jt;
                console.log(this.jobtypes);
            });

        this.report.queryJobs('products').subscribe((res: any) => {
            this.products = res.data;
        });

        this.data = this.ms.getValue().jobs;

        this.setValue(this.data.attributes);
    }

    closeModalX() {
        this.modal.dismiss();
    }

    closeModal() {
        this.modal.dismiss('u');
    }

    submit() {

        const data = {

            'data': {
                'type': 'jobs',
                'id': this.data.id,
                'attributes': {
                    'start_date': moment(this.start_date).format('YYYY-MM-DD'),
                    'status': this.end_date ? 'complete' : 'Pending',
                    'end_date': moment(this.end_date).format('YYYY-MM-DD'),
                    'location': this.location,
                    'merchant_id': this.client,
                    'job_type_id': this.job_type,
                    'product_id': this.product,
                    'comments': this.comments,
                    'ref_no': this.ref1,
                    'ref_no2': this.ref2
                }
            }
        };
        console.log(JSON.stringify(data));
        this.loader();

        this.report.editJob(this.data.id, data).subscribe(async (res: any) => {
            await this.presentToast('Your data has been saved');

            this.closeModal();
            this.loading.dismiss().catch();
        }, async error1 => {
            await this.presentToast('Failed please try again');

            this.loading.dismiss().catch();
        });


    }

    async loader() {
        this.loading = await this.loadingCtrl.create({
            message: 'Please Wait',
            duration: 9000
        });
        this.loading.present();
    }


    async presentToast(id) {
        const toast = await this.toastCtrl.create({
            message: id,
            duration: 3000,

            position: 'top',
        });
        toast.present();
    }

    reset() {

    }

    toNumber(id: any) {
        return Number(id);
    }

    private setValue(attributes) {
        this.start_date = moment(attributes.start_date).format('YYYY-MM-DD');
        this.end_date = moment(attributes.end_date).format('YYYY-MM-DD');
        this.location = attributes.location;
        this.client = Number(attributes.merchant_id);
        this.job_type = attributes.job_type_id;
        this.product = attributes.product_id;
        this.comments = attributes.comments;
        this.ref1 = attributes.ref_no;
        this.ref2 = attributes.ref_no2;
        this.status = attributes.status;
    }
}
