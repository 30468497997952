import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    AlertController,
    LoadingController,
    ModalController,
    ToastController,
} from '@ionic/angular';
import {ReportsService} from '../services/reports.service';
import * as moment from 'moment';
import {Storage} from '@ionic/storage';
import {Index, MerchantFumigator} from '../../models';

@Component({
    selector: 'app-add-job',
    templateUrl: './add-job.page.html',
    styleUrls: ['./add-job.page.scss'],
})
export class AddJobPage implements OnInit {
    comments: any;
    end_date: any;
    location: any;
    start_date: any;
    client: any;
    merchantFumigators: MerchantFumigator[];
    ref2: any;
    ref1: any;
    ventilation_date: any;
    packaging: any;
    grade: any;
    quantity: any;

    public jobtypes: any;
    public products: any;
    product: any;
    job_type: any;
    public loading: any;
    public id: any;

    constructor(
        public modal: ModalController,
        public report: ReportsService,
        public alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public storage: Storage,
        public loadingCtrl: LoadingController,
        private cdr: ChangeDetectorRef
    ) {
    }

    async ngOnInit() {
        await this.report.getFumigatorMerchants()
            .toPromise()
            .then(r => this.merchantFumigators = r.data);

        this.report.queryJobs('jobtypes').subscribe((res: any) => {
            this.jobtypes = res.data;
        });

        this.report.queryJobs('products').subscribe((res: any) => {
            this.products = res.data;
        });

        this.cdr.detectChanges();
        console.log(this.merchantFumigators);
    }

    closeModalX() {
        this.modal.dismiss();
    }

    closeModal() {
        this.modal.dismiss('u');
    }

    submit() {
        const data = {
            data: {
                type: 'jobs',

                attributes: {
                    start_date: moment(this.start_date).format('YYYY-MM-DD'),
                    end_date: moment(this.end_date).format('YYYY-MM-DD'),
                    location: this.location,
                    merchant_id: this.client,
                    job_type_id: this.job_type,
                    product_id: this.product,
                    comments: this.comments,
                    ref_no: this.ref1,
                    ref_no2: this.ref2,
                    grade: this.grade,
                    packaging: this.packaging,
                    quantity: this.quantity,
                    planned_ventilation_date: moment(this.ventilation_date).format('YYYY-MM-DD')
                },
            },
        };
        console.log(JSON.stringify(data));
        this.loader();
        this.report.addJob(data).subscribe(
            (res: any) => {
                this.presentToast('Your data has been saved');
                this.closeModal();
                this.loading.dismiss().catch();
            },
            (error1) => {
                this.presentToast('Failed please try again');

                this.loading.dismiss().catch();
            }
        );
    }

    async loader() {
        this.loading = await this.loadingCtrl.create({
            message: 'Please Wait',
            duration: 9000,
        });
        this.loading.present();
    }

    async presentToast(id) {
        const toast = await this.toastCtrl.create({
            message: id,
            duration: 3000,

            position: 'top',
        });
        toast.present();
    }
}
