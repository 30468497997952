import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MasterDetailService {
    private value: any;

    constructor() {
    }

    public setValue(value) {
        this.value = value;
    }

    getValue() {
        return this.value;
    }
}
